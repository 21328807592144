import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ metadata, location, title, children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          title
          social {
            instagram
          }
        }
      }
    }
  `)

  const { logo, site } = data
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <Link
        style={{
          boxShadow: `none`,
          textDecoration: `none`,
          color: `inherit`,
        }}
        to={`/`}
      >
        <StaticImage
          src={"../images/logo.png"}
          alt={site.siteMetadata.title}
          style={{
            maxWidth: '200px',
            marginBottom: 0,
            minWidth: 50,
          }}
        />
      </Link>
    )
  } else {
    header = (
      <Link
        style={{
          boxShadow: `none`,
          textDecoration: `none`,
          color: `inherit`,
        }}
        to={`/`}
      >
      <StaticImage
        src={"../images/logo.png"}
        alt={site.siteMetadata.title}
        style={{
          maxWidth: '100px',
          marginBottom: 0,
          minWidth: 50,
        }}
      />
      </Link>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, SLiM Poetry
      </footer>
    </div>
  )
}

export default Layout
